import Common from "./common.js"

class Home extends Common {
  constructor() {
    super();
    var CI = this;
    this.getFormDetails("#msform");
    this.TogglePopUp()
    this.ToggleCheckBox()

    var current_fs, next_fs, previous_fs; //fieldsets
    var opacity;
    var current = 1;
    var currentTab = 0;
    this.steps = $(".form-card").length;

    this.setProgressBar(current);
    $(document).ready(function() {
      // Set the countdown time initially (8 hours and 25 minutes in seconds)
      var countdownTime = 8 * 3600 + 25 * 60;
  
      function updateClock() {
          // Calculate hours, minutes, and seconds from countdownTime
          var hours = Math.floor(countdownTime / 3600);
          var minutes = Math.floor((countdownTime % 3600) / 60);
          var seconds = countdownTime % 60;
  
          // Format time to have two digits
          hours = ("0" + hours).slice(-2);
          minutes = ("0" + minutes).slice(-2);
          seconds = ("0" + seconds).slice(-2);
  
          // Create digit elements
          var digits = [hours[0], hours[1], ':', minutes[0], minutes[1], ':', seconds[0], seconds[1]];
          var html = '';
          digits.forEach(function(digit) {
              if (digit === ':') {
                  html += '<div class="digitColon mx-1">' + digit + '</div>';
              } else {
                  html += '<div class="digit">' + digit + '</div>';
              }
          });
  
          // Update the HTML of the clock
          $('#clock').html(html);
  
          // Decrement the countdown time
          countdownTime--;
  
          // Stop the timer if it reaches zero
          if (countdownTime < 0) {
              clearInterval(intervalId);
              $('#clock').html("<div class='digit'>Time's up!</div>");
          }
      }
  
      // Start the countdown timer
      var intervalId = setInterval(updateClock, 1000);
      updateClock(); // Initial update
    });
    $("#viewMoreBtn").click(function(){
      const extraDeals = document.querySelectorAll('.extra-deal');
      extraDeals.forEach(deal => {
        deal.classList.toggle('d-none');
      });
    
      // Optionally, hide the 'View More' button after use
      const viewMoreBtn = document.getElementById('viewMoreBtn');
      if (viewMoreBtn.innerText === 'View more deals') {
        viewMoreBtn.innerText = 'View less deals';
      } else {
        viewMoreBtn.innerText = 'View more deals';
        // Scroll back to top or to the first element of extra deals
        window.scrollTo(0, extraDeals[0].offsetTop);
      }
    })
    $(document).ready(function() {
      
      // Example: Go to the second slide on button click
      $("#gotoSecond").click(function() {
        $(".carousel").carousel(1); // Carousel index starts at 0
      });
  
      // Listen to the slide event
      $('#carouselExampleControls').on('slide.bs.carousel', function () {
        console.log('Carousel slide event fired!');
      });
    });

    $( ".property" ).change(function() {
      $('.towncity').val($(this).find("option:selected").data("city"))
      $('.street1').val($(this).find("option:selected").data("street"))
      $('.county').val($(this).find("option:selected").data("province"))
      $('.street2').val($(this).find("option:selected").data("street2"))
      $('.building').val($(this).find("option:selected").data("building"))
    });


    $(".next").click(function(){
      current_fs = $(this).parent();
      next_fs = $(this).parent().next();
      //Add Class Active
      $('#msform').parsley().whenValidate({
        group: `block-${current-1}`
      }).done(() =>{
        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        $(current_fs).animate({opacity: 0}, {
          step: function(now) {
          // for making fielset appear animation
            opacity = 1 - now;

            $(current_fs).css({
              'display': 'none',
              'position': 'relative'
            });
            next_fs.css({'opacity': opacity});
          },
          duration: 500
        });
        if (current == 5) {
          CI.postData();
        }
        CI.setProgressBar(++current);
      })
    });

    $(".previous").click(function(){
      current_fs = $(this).parent();
      previous_fs = $(this).parent().prev();
      //Remove class active
      $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
      //show the previous fieldset
      previous_fs.show();
      //hide the current fieldset with style
      $(current_fs).animate({opacity: 0}, {
        step: function(now) {
          // for making fielset appear animation
          opacity = 1 - now;
          $(current_fs).css({
            'display': 'none',
            'position': 'relative'
          });
          previous_fs.css({'opacity': opacity});
        },
        duration: 500
      });
      CI.setProgressBar(--current);
    });


    $(".submit").click(function(){
    return false;
    })  

    // $(document).on("click", '.open-form', function() {
    //     $('#formpopup').show()
    //     event.stopPropagation()
    // });  

  }

  setProgressBar(curStep){
    var percent = parseFloat(100 / this.steps) * curStep;
    percent = percent.toFixed();
    $(".progress-bar")
    .css("width",percent+"%")
  }

}
export default new Home();
